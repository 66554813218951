<script lang="ts" setup></script>

<template>
  <div class="bg-[url('/images/landing/passion.webp')] bg-cover [background-position:22%] lg:bg-top 2xl:bg-bottom">
    <div class="container h-[560px] px-4 text-center">
      <div class="mx-auto max-w-[500px] rounded-b-3xl bg-[rgba(251,251,251,0.23)] px-6 py-16 backdrop-blur-md">
        <UIHeading size="h2" styleSize="h2" customClasses="font-normal">
          <UIHtml :html="$t('general.landing.feelConfident')" />
        </UIHeading>
      </div>
    </div>
  </div>
</template>
